/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9167be96-7b93-44fe-aa8d-36c5db00b07b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_agB73d7h0",
    "aws_user_pools_web_client_id": "3shpr98b22molnvvgb6i33rnsh",
    "oauth": {},
    "aws_user_files_s3_bucket": "meddy-uploads-3220049-devl",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
